.container{
       flex: 1;
       align-items: center;
       justify-content: center;
  }

  .title{
    margin-top: 15%;
    /* align-self: center; */
    align-items: center;
    margin-left: 32%;
    font-size: 3rem !important;
    font-weight: bold;
  }
  .wifi_Img{
    margin-top: 25%;
    margin-left: 40%;
    /* width: 150px !important; */
    /* align-self: center; */
    fill:  #a52b3b !important ;
    font-size: 10rem !important;
  }

  @media (max-width: 500px) {
    .title{
        margin-top: 25%;
        /* align-self: center; */
        align-items: center;
        margin-left: 18%;
        font-size: 1.8rem !important;
       font-weight: normal;
      }
      .wifi_Img{
        margin-top: 50%;
        margin-left: 40%;
        /* width: 150px !important; */
        /* align-self: center; */
        fill:  #a52b3b !important ;
        font-size: 5rem !important;
      }
    
  }