/* body{
    margin: 0;
    padding: 0;
    background: #262626;
} */
/* .dispense{
    background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%);
} */

.dispense {
  /* background-image: url("coffee-cup.jpg"); */
  /* background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%); */
  background-color: #ffffff;
  background-size: cover;
  /* min-width: 100%;
    min-height: 100%; */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}
.dispense_complete {
  /* background-image: url("coffee-cup.jpg"); */
  /* background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%); */
  background-color: #a52b3b;
  background-size: cover;
  /* min-width: 100%;
    min-height: 100%; */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}
.machine {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.cup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 180px;
  /* border: 6px solid #e6b980; */
  border: 6px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 0 0 75px 75px;
  border-top-left-radius: 5px;
  background: url(coffeeAnimate.png);
  background-repeat: repeat-x;
  animation: animate 20s linear infinite;
  box-shadow: 0 0 0 6px #262626, 0 20px 35px rgba(0, 0, 0, 1);
}
.discription {
  position: absolute;
  top: 80%;
  text-align: center;
  color: #000000;
  font-weight: bolder;
  align-content: center;
  /*    position: absolute;*/
  /* text-shadow: 2px 4px 15px black; */
  font-size: 20px;
  /* font-family: "Courier New"; */
  font-family: "Raleway", sans-serif;
  /* color: whitesmoke; */
  left: 50%;
  transform: translate(-50%, -50%);
}

.headingStyle {
  /* text-align: center;
    align-content: center;
    width: max-content;
    margin-top: 25%;
   position: absolute; */
  text-align: center;
  align-content: center;
  /*    position: absolute;*/
  font-size: 20px;
  font-weight: bold;
  font-family: cursive;
  /* text-shadow: 2px 4px 15px black; */
  /* font-family: "Courier New"; */
  color: #000000;
  margin-top: 25px;
  font-family: "Raleway", sans-serif;
}
.cup:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 80px;
  top: 30px;
  right: -68px;
  border: 6px solid #262626;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

@keyframes animate {
  0% {
    background-position: 0 100px;
  }
  10% {
    background-position: 0 100px;
  }
  40% {
    background-position: 1000px -20px;
  }
  0% {
    background-position: 0 100px;
  }
}

.maindiv {
  /* width: 100px; */
  /* height: 100px; */
  /* background: #1D2B64;  fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #F8CDDA, #1D2B64);  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #F8CDDA, #1D2B64); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+     */
}

@media (max-width: 500px) {
  .cup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 140px;
    /* border: 6px solid #e6b980; */
    border: 6px solid #ffffff;
    border-top: 2px solid transparent;
    border-radius: 0 0 75px 75px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* background: url(coffee.png); */
    background: url(coffeeAnimate.png);
    background-repeat: repeat-x;
    animation: animate 20s linear infinite;
    box-shadow: 0 0 0 6px #262626, 0 10px 25px rgba(0, 0, 0, 1) !important;
  }
  .coffee_cup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 140px;
    /* border: 6px solid #e6b980; */
    border: 6px solid #a52b3b;
    border-top: 2px solid transparent;
    border-radius: 0 0 75px 75px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* background: url(coffee.png); */
    background: url(coffee_copy.png);
    /* filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%)
      contrast(80%); */
    background-repeat: repeat-x;
    animation: animate 20s linear infinite;
    box-shadow: 0 0 0 6px #262626, 0 10px 25px rgba(0, 0, 0, 1) !important;
  }
  coffee_cup background {
    filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%)
      contrast(80%);
  }

  .cup:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 60px;
    top: 21px;
    right: -48px;
    border: 6px solid #262626 !important;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
  }
  .coffee_cup:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 60px;
    top: 21px;
    right: -48px;
    border: 6px solid #262626 !important;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
  }
}
