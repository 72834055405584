.login {
  background-image: url("coffee-cup.jpg");
  background-size: cover;
  /* min-width: 100%;
  min-height: 100%; */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}
.kaapi_logo {
  border: 1px solid #4e342e;
  padding: 5px;
  height: 120px;
  width: 150px;
  border-radius: 50%;
  margin-top: 5px;
  border-width: 5px;
}
@media (max-width: 500px) {
  .kaapi_logo {
    border: 1px solid #4e342e;
    padding: 5px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-top: 5px;
    border-width: 5px;
  }
}
