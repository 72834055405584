.menu {
  /* background-image: url('coffee-cup.jpg');
      filter: blur(10px);
      -webkit-filter: blur(10px); */
}
.line {
  border-bottom: 2px solid #ffffff;
}
.heading {
  /* background-color: #dfa579; */
  background-color: #ffffff;
  /* background-image: linear-gradient(to top, #c79081 0%, #dfa579 100%); */
  text-align: center;
}
.recipes {
  /* background-color: #dfa579; */
  /* background-image: linear-gradient(to top, #c79081 0%, #dfa579 100%); */
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.recipes :hover {
  cursor: pointer;
  background-color: #2f2e2e;
  color: #ffffff;
}
.Section {
  /* background-image: url(coffee-cup.jpg); */
  background-image: url(cupOfcoffee.jpg);

  background-size: cover;
  width: 100%;
  height: 350px;
}
.section_List {
  background-color: #ffffff;
  margin-left: 29px;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  font-size: medium;
  text-indent: 0;
  /* padding: 20px 0px 0px 0px; */
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
}
@media (max-width: 800px) {
  .Section {
    background-image: url(cupOfcoffee.jpg);
    object-fit: cover;
    /* background-size: cover;
    
    width: 100%; */
    height: 100px;
    font-family: "Raleway", sans-serif;
  }
  .line {
    border-bottom: 2px solid #ffffff;
  }
}

.underLine {
  width: 350px;
  height: 50px;
  /* background-color: #e6b980; */
}
@media (max-width: 500px) {
  .underLine {
    width: 200px;
    height: 40px;
    /* top: 5px; */
    /* background-color: #e6b980; */
  }
  .heading {
    /* background-color: #dfa579; */
    background-color: #ffffff;
    /* background-image: linear-gradient(to top, #c79081 0%, #dfa579 100%); */
    white-space: 2px;
    /* margin: 2px; */
    text-align: center;
    font-size: 13px;
    font-family: "Raleway", sans-serif;
  }
  .headingMenu {
    /* padding-bottom: 2px; */
    font-family: "Raleway", sans-serif;
  }
  .recipes {
    background-color: #ffffff;
    /* background-color: #dfa579; */
    /* background-image: linear-gradient(to top, #c79081 0%, #dfa579 100%); */
    display: flex;
    /* position: relative; */
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .line {
    border-bottom: 2px solid #2b2a29;
  }
}
