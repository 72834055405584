.navbar {
  display: flex;
  position: relative;
  /* position: fixed;
    width: 100%; */
  justify-content: space-between;
  align-items: center;
  font-family: "Raleway", sans-serif;
  background-color: #000000;
  /* background-color:#795548 ; */
  color: white;
}
.kaapiLogo {
  border: 1px solid #4e342e;
  padding: 5px;
  max-width: 90px;
  border-radius: 50%;
  border-width: 5px;
  margin-left: 20px;
  /* borderRadius:50%;
    marginTop:5px;
    borderWidth:5px; */
}
.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem;
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 1rem;
}

.navbar-links li:hover {
  background-color: #555;
}

.toggle-button {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }
  .kaapiLogo {
    border: 1px solid #4e342e;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 50px;
    max-width: 90px;
    margin-left: 20px;
    border-radius: 50%;
    border-width: 5px;
    /* borderRadius:50%;
      marginTop:5px;
      borderWidth:5px; */
  }
}
