body {
  margin: 0;
  /* background-color: #e6b980; */
  /* background-image: url("coffee-cup.jpg"); */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
}

code {
  font-family: "Raleway", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
.login {
  background-image: url("coffee-cup.jpg");
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
}
