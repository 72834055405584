.profile_page {
  background-image: url("coffee-cup.jpg");
  background-size: cover;
  /* min-width: 100%;
  min-height: 100%; */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

.card-container {
  /* background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%); */
  background: #a1887f;
  align-content: center;
  align-items: center;
  width: 350px;
  height: 470px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 10px 1px #000;
  overflow: hidden;
  display: inline-block;
}
.profile_kaapi_logo {
  border: 1px solid #4e342e;
  padding: 5px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-top: 5px;
  border-width: 5px;
  margin-left: 35%;
}
.profile_heading {
  text-align: center;
}
.lower-container h3,
h4,
h5 {
  box-sizing: border-box;
  line-height: 0.6;

  margin-top: 9px;
}
.lower-container h4 {
  /* font-weight: lighter; */
  color: #795548;
  opacity: 0.8;
  font-weight: bclold;
}
.lower-container h5 {
  color: black;
  font-weight: bold;
}
.lower-container .btn {
  margin-top: 10px;
  background: #795548;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in;
  margin-top: 15px;
}
.lower-container .btn:hover {
  /* background: transparent;
color:#795548;
border: 2px solid #795548; */
}

.lower_item {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: 25px;
  margin-top: 5px;
}

.user_name {
  font-size: 65%;
  border-top-style: hidden;
  border-bottom-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  background: #a1887f;
}
.user_email {
  font-size: 65%;
  border-top-style: hidden;
  border-bottom-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  background: #a1887f;
}
.user_mobileNo {
  font-size: 65%;
  border-top-style: hidden;
  border-bottom-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  background: #a1887f;
  font-weight: 60px;
}
textarea:focus,
input:focus {
  color: #0a0a0a;
}

input,
select,
textarea {
  color: #0a0808;
}

.lower-container {
  height: 280px;
  padding: 20px;
  padding-top: 50px;
  text-align: center;
}
.lower_item {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: 25px;
  margin-top: 5px;
}
@media (max-width: 500px) {
  .card-container {
    /* background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%); */
    align-content: center;
    align-items: center;
    width: 320px;
    height: 420px;
    background: #a1887f;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 10px 1px #000;
    overflow: hidden;
    display: inline-block;
  }
  .profile_heading {
    text-align: center;
    padding-top: 8px;
  }
  .profile_kaapi_logo {
    border: 1px solid #4e342e;
    padding: 5px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-top: 5px;
    border-width: 5px;
    margin-left: 35%;
  }

  .lower-container {
    height: 220px;
    padding-top: 3px;
    padding-left: 3px;
    text-align: center;
  }
  .lower_item {
    display: flex;
    margin-left: 42px;
    margin-right: auto;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: 35px;
    margin-top: 5px;
  }
  .label {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .close {
    /* margin-top: 40px;
    margin-left: 12%;
    width: 100px; */
    /* cursor: pointer;
    position: absolute; */
    display: block;
    padding: 2px 5px !important;
    line-height: 50px;
    right: -1px;
    top: -8px;
    /* font-size: 25px; */
    background: #51aace;
    border-radius: 25px;
    border: 1px solid #cfcece;
    border-radius: 8px;
    align-items: center;
  }
}
