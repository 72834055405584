.recipe {
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(71, 71, 71);
  margin: 20px;
  display: flex;
  background-color: #a52b3b;
  /* background: rgb(133, 71, 71); */
  align-items: center;
  min-width: 40%;
}

.image {
  float: left;
  margin: 8px 15px 8px 8px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.heading {
  font-size: 20px;
  float: left;
  padding: 30px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
}
.pricing_style{
  font-size: 20px;
  float: left;
  padding: 30px 0px 0px 0px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
}

@media (max-width: 500px) {
  .recipe {
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgb(71, 71, 71);
    margin: 10px;
    display: flex;
    background-color: #a52b3b;

    /* background: rgb(133, 71, 71); */
    align-items: center;
    min-width: 80%;
  }

  .image {
    position: relative;
    float: left;
    margin: 8px 15px 8px 8px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin-left: px;
  }
  .heading {
    font-size: 17px;
    font-weight: 500;
    padding: 20px 0px 0px 0px;
    text-transform: capitalize;
    font-family: "Raleway", sans-serif;
    /* float:left;
        margin: 8px 15px 8px 8px; */
  }
  .heading:hover {
    color: #ffffff;
  }
  .recipe:hover {
    background-color: #2f2e2e;
    color: #ffffff;
  }
  .pricing_style{
    font-size: 19px;
    /* float: left; */
    padding: 0px 0px 0px 0px;
    margin-top: 8px;
    font-weight: 400;
    color: #000;
    /* text-transform: capitalize; */
    /* font-family: "Raleway", sans-serif; */
    /* margin-left: 20px; */
     margin-right: 15px;
  }
  .div_container{
    /* display: flex; */
    flex: 1;
  }
}
