.home {
  height: 100vh;
  background-color: #bd4f6c;
  background-image: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);

  /* background-color: #faaca8;
  background-image: linear-gradient(19deg, #faaca8 0%, #ddd6f3 100%); */

  /* background-color: #e6b980; */
  display: grid;
  place-items: center;
}
@media (max-width: 500px) {
  .home_Img {
    border: 1px solid #4e342e;
    padding: 5px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border-width: 5px;
    margin-left: "25px";

    animation: shake 0.9s;
    /* animation-iteration-count: 30s; */
  }
  .home_container {
    align-items: center;
  }
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
